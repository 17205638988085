import React from "react";
import "./Nav.css";
import { DropdownButton, Dropdown } from 'react-bootstrap'
import 'react-dropdown/style.css'
import Popup from "reactjs-popup";

const Navbar = props => {
  const navCubes = [];

  function reloadPage(){
    setTimeout(function(){
      window.location.reload();
    }, 50);
  }
  for (let i = 2; i <= 10; i++) {
    navCubes.push(
      <Dropdown.Item key={i} href={`/#id=${i}`} onClick={reloadPage}>{i} X {i}</Dropdown.Item>
    )
  }
  return (
    <nav className="navbar navbar-dark fixed-top">
      <ul className="nav nav-justified mr-auto">
        <li className="nav-item nav-fix-for-edge">
          <p className="navbar-brand"><a href="/">Cube-Solver<span>.com</span></a></p>

          {/*Open model here. Show bunch of settings. Pass changeSettings down to component to apply changes*/}
          <div className="settingsDropDown">
            <DropdownButton className="settings" aria-label="settings" id="settings" title={<i className='settingIcon'>Settings</i>}>
              <ul>
                <li className="settingsDropDownItem">
                  <div className="checkbox-inline">
                      <input type="checkbox" data-toggle="toggle" onClick={() => props.changeSettings('displayMoveInput')}></input>
                  </div>
              Move Input
            </li>
                <li className="settingsDropDownItem" onClick={() => props.changeSettings('displayHints')}>
                  <div className="checkbox-inline">
                      <input type="checkbox" defaultChecked data-toggle="toggle" onClick={() => props.changeSettings('displayHints')}></input>
                  </div>
              Move Hints

            </li>

              </ul>

            </DropdownButton>
          </div>
          <div className="cubesDropdown">
            <DropdownButton title="Cubes" className="cubes">
              {navCubes}
            </DropdownButton>
          </div>
        </li>
      </ul>
      <div style={{ float: "right", height: "100%" }} >
        <Popup trigger={<button id="infoBtn">Info</button>}>
          {close => (
            <div style={{ zIndex: "100", width: "100%", height: "100%" }}>
              <div className="shadeBackground" style={{ backgroundColor: "black", zIndex: "101" }} onClick={close}></div>
              <div style={{ zIndex: "102", width: "max-content", margin: "auto", transform: "translateX(-50%)", padding: "8px" }} className="popupDiv">
                <b style={{ fontSize: "1.5rem" }}>Instructions</b>
                <div className="close" id="closeBtn" onClick={close}>
                  &times;
                </div>
                <div style={{ backgroundColor: "rgba(0,0,0,.8)" }}>
			<article>
				  <h1>Online NxN Rubik's Cube Solver and Simulator</h1>
                  <p><strong>Online Rubik's Cube, 4x4x4 and other NxNxN cube solver and simulator. Set up a scramble rotating the faces of the puzzle or using the color picker to find the rotations leading to the solution. Apply a sequence of moves, perform a random scramble or reset to the solved state with a click of a button. First select the desired cube size in the menu: 2x2 Pocket Cube, 3x3 Rubik's Cube, 4x4 Revenge, 5x4 Professors, 6x6x6 up to 10x10x10 cube to get started.</strong></p>
				  <h2>Cube Simulators</h2>
				  <p>Play with the cube online dragging the faces and layers with your mouse or swipe the screen on your touch/mobile device. Rotate the whole cube dragging the screen outside of the cube. Scroll to zoom in and out.</p>
				  <p>Apply a random scramble with the <strong>Scramble</strong> button then try to find the solution. Dragging the faces or using the keyboard commands:</p>
                  <div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <b style={{ color: "white", backgroundColor: "black" }}> F, </b><b style={{ color: "white", backgroundColor: "black" }}>f,</b>
                      <b style={{ color: "blue", backgroundColor: "black" }}> U, </b><b style={{ color: "blue", backgroundColor: "black" }}>u,</b>
                      <b style={{ color: "red", backgroundColor: "black" }}> R, </b><b style={{ color: "red", backgroundColor: "black" }}>r,</b>
                      <b style={{ color: "yellow", backgroundColor: "black" }}> B, </b><b style={{ color: "yellow", backgroundColor: "black" }}>b,</b>
                      <b style={{ color: "orange", backgroundColor: "black" }}> L, </b><b style={{ color: "orange", backgroundColor: "black" }}>l,</b>
                      <b style={{ color: "green", backgroundColor: "black" }}> D, </b><b style={{ color: "green", backgroundColor: "black" }}>d </b>
                      <p style={{ width: "100%", textAlign: "center" }}>(lower case is clockwise, upper case is counterclockwise)</p>
                    </div>
                  </div>
				  <p>Visit <a href="https://rubikscu.be/" target="_blank" rel="noreferrer">RubiksCu.be</a>, another cube simulator if this one doesn't render correctly on your device or you have any difficulties using it.</p>
				  <h2>Cube Solver</h2>
				  <p>Those who can't <a href="https://cubesolve.com/">solve the Rubik's Cube</a> might need some help. Input  a scrambled cube manually, with the scramble button or with the color picker and let the program find the solution for you.</p>
				  <p>The program finds the solution of the Rubik's Cube in roughly 140 steps with a layer-by-layer method. It solves the 4x4x4 and larger cubes grouping the centers and edges and finally solving it like a 3x3. This process requires about 700 rotations for a 4x4x4 puzzle. Press the play back button and watch the animation as you follow the solution with the desired speed or click through the moves list one by one.</p>
				  <p>For the optimal cube solver check out <a href="https://rubiks-cube-solver.com/" target="_blank" rel="noreferrer">rubiks-cube-solver.com</a>, a program that fixes the cube in 20 steps or less.</p>
				  <h2>Patterns</h2>
				  <p>Add a new challenge to the game. Try to create pretty <a href="https://ruwix.com/the-rubiks-cube/rubiks-cube-patterns-algorithms/">Rubik's Cube patterns</a>. Under the Patterns menu select an effect and a special scramble will be shown on the screen with the algorithm to reach it.</p>
			</article>
			<footer style={{}}>
			  ©Cube-Solver.com | All&nbsp;rights&nbsp;reserved | Author: Sasha Peters <a rel="noreferrer" target="_blank" href="https://www.github.com/merpig">GitHub</a> <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/alexandr-sasha-peters-8a2489168/">LinkedIn</a><br></br>This&nbsp;website is&nbsp;using&nbsp;cookies to&nbsp;collect anonymous visitor analytics and to show&nbsp;tailored&nbsp;ads.
            </footer>
                </div>
              </div>
            </div>
          )}
        </Popup> {" "}
        <button id="fullscreenBtn" value="true">FullScreen</button>
      </div>
    </nav>)
};

export default Navbar;
